import * as React from 'react';
import Container from '../layout/Container';

interface Props {
    category: string;
}
const CategoryDescriptionText: React.FunctionComponent<Props> = ({ category }) => {
    return (
        <Container className="mt-16">
            <h2 className="mb-4">Vegetariske opskrifter i {category} kategorien</h2>
            <hr className="w-48 mb-4 border-2" />
            <p className="text-gray-900 mt-1 mb-3">
                Det er ikke altid let at finde rundt i de mange opskrifter på nettet. Med vegetar.dk får du et samlet sted med alle vegetariske opskrifter.
                Her kan du altid finde inspiration til din næste opskrift. Vi har inddelt opskrifter i forskellige kategorier og givet dem nøgleord, som du også kan søge på.
                Her ovenover ser du opskrifterne for: {category}.
            </p>
            <h5>Nemt og tilgængeligt</h5>
            <p className="text-gray-900 mt-1 mb-3">
                Et af vores fokus punkter er, at vegetarisk madlavning skal være tilgængeligt for alle.
                Vi fokuserer derfor på, at mange af opskrifterne kan laves relativt nemt og hurtigt.
                Mange har nemlig ikke tiden i hverdagen til at fordybe sig og nørde med madlavningen.
                På denne side kan du lede efter lette og tilgængelige opskrifter i kategorien: {category}.
            </p>
            <h5>Send dine egne opskrifter</h5>
            <p className="text-gray-900 mt-1 mb-3">
                Vi leder altid efter flere vegetariske opskrifter på vores side.
                Du må altid gerne kontakte os, hvis du ligger inde med en opskrift, som andre bare skal prøve.
                Der er så mange vegetariske opskrifter i dag, at det kan være svært at finde de gode.
                Du er derfor meget velkommen til at kontakte os og sende dine gode tips til madlavningen.
                Det kan være til vores "{category}" eller en af de mange andre.
                Vi nok sørge for, at de kommer ind på hjemmesiden.
            </p>
        </Container>
    );
}

export default CategoryDescriptionText;